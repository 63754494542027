@import "~rsuite/lib/styles/index.less";
@import (css)
  url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap");

.App {
  background-color: #f4f4f4;
  //background-image: url("/images/bg.png");
  //background-size: cover;
}

.csv-reader-input input {
  border-radius: 5px;
  padding: 5px;
}

.warning-modal > .rs-modal-dialog > .rs-modal-content {
  padding: 0;
  border-radius: 7px;
}

.login {
  margin: auto;
  background-color: #38b1c1;
  color: white;
  //border: 1px solid #949494;
  //background-color: #949494;
  box-sizing: border-box;
  box-shadow: rgba(148, 148, 148, 0.9) 0px 0px 1px,
    rgba(148, 148, 148, 0.7) 0px 16px 24px -8px,
    rgba(148, 148, 148, 0.6) 0px 32px 48px -16px;
  //box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px,
  //  rgba(67, 90, 111, 0.47) 0px 16px 24px -8px;
}

.button-shadow {
  box-shadow: rgba(175, 148, 148, 0.15) 0px 0px 1px,
    rgba(175, 148, 148, 0.25) 0px 8px 12px -4px;
}

.button-glowing {
  animation: button-glow 500ms ease-out infinite alternate;
  box-shadow: rgba(175, 148, 148, 0.15) 0px 0px 1px,
    rgba(175, 148, 148, 0.25) 0px 8px 12px -4px;
}

@keyframes button-glow {
  0% {
    box-shadow: rgba(175, 148, 148, 0.15) 0px 0px 1px,
      rgba(175, 148, 148, 0.25) 0px 8px 12px -4px;
  }
  100% {
    box-shadow: rgba(255, 148, 148, 0.7) 0px 0px 1px,
      rgba(255, 148, 148, 0.9) 0px 8px 12px -4px;
  }
}

.header {
  box-shadow: rgba(148, 148, 148, 0.6) 0px 0px 1px,
    rgba(148, 148, 148, 0.4) 0px 16px 24px -8px;
}

.expanded {
  transform: scaleY(100%);
  transition: all 600ms;
  transition: height 600ms ease-in;
}

.collapsed {
  opacity: 0;
  //display: none;
  transform: scaleY(0%);
  transition: all 600ms;
  transition: height 600ms ease-out;
}

@base-color: #38B1C1;